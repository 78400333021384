<template>
  <!-- 投诉反馈 -->
  <div ref="complaintFeedback" class="complaintFeedback">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1 col-1-a">渠道：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="channelNos" placeholder="请选择渠道">
            <el-option
              v-for="item in channelNoList"
              :key="item.channelNo"
              :label="item.channelName"
              :value="item.channelNo"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">反馈时间段：</div>
        <div class="col-2">
          <el-date-picker
            v-model="timeBucketValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">用户类型：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="userType" clearable placeholder="请选择">
            <el-option
              v-for="item in userTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">状态：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="state" clearable placeholder="请选择">
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">处理人：</div>
        <div class="col-2 col-2-a">
          <el-input v-model="handlerValue"></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">反馈类型：</div>
        <div class="col-2">
          <el-cascader
            v-model="feedbackTypes"
            :options="feedbackTypesOptions"
            :props="feedbackTypesProps"
            collapse-tags
            clearable
          ></el-cascader>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun(1)"
          >查询</el-button
        >
      </div>
    </div>
    <Table
      :table-height="TableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :emptyText="emptyText"
      operation-width="110"
      class="feedbackTable"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="openPopupFun(1, scope.scopeRow)"
          >查看</el-button
        >
        <el-button
          v-if="listFind('处理') && scope.scopeRow.status == 0"
          type="text"
          size="small"
          @click="openPopupFun(2, scope.scopeRow)"
          >处理</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 弹窗 -->
    <div v-if="showPopup" class="feedbackPopup">
      <div class="box">
        <!-- header -->
        <div class="header">
          <div class="text">{{ headerText }}</div>
          <div class="close" @click="closeFun()"></div>
        </div>
        <div class="content">
          <div class="row">
            <div class="row-l">反馈内容：</div>
            <div class="row-r">
              <div class="div-textarea">{{ complaintContent }}</div>
            </div>
          </div>
          <div class="row">
            <div class="row-l">用户手机：</div>
            <div class="row-r">
              <div class="div-textarea">{{ passengerPhone }}</div>
            </div>
          </div>
          <div class="row">
            <div class="row-l">联系方式：</div>
            <div class="row-r">
              <div class="div-textarea">{{ passengerContactInformation }}</div>
            </div>
          </div>
          <div class="row">
            <div class="row-l">附件：</div>
            <div class="row-r">
              <div class="div-textarea">
                <img
                  v-for="item in imageArray"
                  v-show="imageArray.length > 0"
                  :key="item"
                  :src="host.imgURL + item"
                  @click="imgPopupFun(item)"
                />
                <span v-show="imageArray.length == 0">无</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-l">处理意见：</div>
            <div class="row-r">
              <el-input
                v-model="feedBackTextarea"
                :disabled="disabled"
                type="textarea"
              ></el-input>
            </div>
          </div>
          <div class="row">
            <div class="row-l">处理人：</div>
            <div class="row-r">
              <div>{{ handlerName }}</div>
            </div>
          </div>
          <div class="row">
            <div class="row-l">处理时间：</div>
            <div class="row-r">
              <div class="div-textarea">{{ currentTime }}</div>
            </div>
          </div>
        </div>
        <!-- footer -->
        <div class="footer">
          <div>
            <el-button v-show="!disabled" @click="confirmHandleFun"
              >确认</el-button
            >
            <el-button @click="closeFun">取消</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 图片弹框 -->
    <div
      v-show="imgPopupShow"
      class="img_popup"
      @click="
        () => {
          this.imgPopupShow = false;
        }
      "
    >
      <img :src="imgSrc" />
    </div>
  </div>
</template>

<script>
import {
  feedbackAPI,
  feedbackHandleAPI,
  getCurrentUserChannelInfoAPI,
  feedbackTypeTreeAPI,
} from "@/api/lib/api.js";
import host from "../../../api/static/host.js";
export default {
  data() {
    return {
      emptyText: "暂无数据",
      feedBackID: "",
      feedBackTextarea: "",
      imgPopupShow: false,
      imgSrc: "",
      token: "",
      currentTime: "",
      host: null,
      imageArray: [],
      passengerContactInformation: "",
      passengerPhone: "",
      complaintContent: "",
      handlerName: "",
      handleTime: "",
      showPopup: false,
      headerText: "查看",
      tableData: [],
      titleName: [
        {
          title: "渠道",
          props: "channelName",
          width: "170",
        },
        {
          title: "反馈内容",
          props: "complaintContent",
          render: (h, params, index) => {
            return h(
              "div",
              {
                class: [
                  "complaintContent",
                  params.backendState == 0 ? "newInfo" : "",
                ],
              },
              [params.complaintContent]
            );
          },
        },
        {
          title: "反馈类型",
          props: "feedbackTypeName",
          width: "170",
        },
        {
          title: "反馈时间",
          props: "createTime",
          width: "170",
        },
        {
          title: "用户类型",
          props: "userType",
          SpecialJudgment: (res) => {
            return res == 1 ? "司机" : res == 2 ? "普通用户" : "";
          },
        },
        {
          title: "用户手机号",
          props: "passengerPhone",
          width: "120",
        },
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "联系方式",
          props: "passengerContactInformation",
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0
              ? "未处理"
              : res == 1
              ? "处理中"
              : res == 2
              ? "已解决"
              : res == 3
              ? "已关闭"
              : "";
          },
        },
        {
          title: "处理意见",
          props: "handlingOpinion",
        },
        {
          title: "处理人",
          props: "handlerName",
        },
        {
          title: "处理时间",
          props: "handleTime",
        },
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      channelNoList: [], //渠道列表
      channelNos: [], //渠道
      timeBucketValue: "", //反馈时间段
      userType: "", //用户类型
      state: "", //状态
      handlerValue: "", //处理人
      feedbackTypes: null, //反馈类型
      feedbackTypesOptions: [],
      feedbackTypesProps: {
        value: "id",
        label: "typeName",
        children: "childFeedbackType",
        emitPath: false,
        multiple: true,
      },
      stateList: [
        { value: 0, label: "未处理" },
        { value: 1, label: "处理中" },
        { value: 2, label: "已解决" },
        { value: 3, label: "已关闭" },
      ],
      userTypeList: [
        { value: 2, label: "普通用户" },
        { value: 1, label: "司机" },
      ],
      TableHeight: 0,
      disabled: false,
    };
  },
  watch: {
    channelNos(val) {
      if (val) {
        this.feedbackTypes = null;
        this.getFeedbackTypeTreeFun();
      }
    },
  },
  created() {
    this.handlerName = sessionStorage.getItem("username");
    this.host = host;
    this.token = sessionStorage.getItem("token");
    //如果有就读取缓存里面的数据作缓存(搜索条件做缓存)
    if (sessionStorage.getItem("complaintFeedbackFlag")) {
      const {
        pageSize,
        currentPage,
        userType,
        handlerName,
        status,
        channelNos,
        createTimeBegin,
        createTimeEnd,
      } = JSON.parse(sessionStorage.getItem("complaintFeedbackSearch"));
      this.pageSize = pageSize;
      this.currentPage = currentPage;
      this.userType = userType;
      this.handlerValue = handlerName;
      this.state = status;
      // this.channelNos = channelNos;
      if (createTimeBegin && createTimeEnd) {
        this.timeBucketValue = [createTimeBegin, createTimeEnd];
      }
    } else {
      //其他页面第一次进入列表页，清掉缓存里面的数据
      this.pageSize = 10;
      this.currentPage = 1;
      this.userType = "";
      this.handlerValue = "";
      this.state = "";
      this.channelNos = [];
      this.timeBucketValue = "";
    }
  },
  mounted() {
    this.getQueryChannelList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 获取反馈类型列表树结构
    getFeedbackTypeTreeFun() {
      feedbackTypeTreeAPI({ channelNo: this.channelNos }).then((res) => {
        if (res.code === "SUCCESS" && res.data.length) {
          this.feedbackTypesOptions = res.data;
        }
      });
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelNoList = res.data || [];
            if (!res.data.length) {
              this.emptyText = "暂无权限，请联系管理员";
              this.$message.error("暂无权限，请联系管理员");
              return;
            }
            this.channelNos = res.data[0].channelNo;
          }
          this.queryFun();
        })
        .catch(() => {
          this.emptyText = "暂无权限，请联系管理员";
        });
    },
    // 点击查看图片
    imgPopupFun(item) {
      this.imgPopupShow = true;
      this.imgSrc = host.imgURL + item;
    },
    // 点击确认按钮
    confirmHandleFun() {
      feedbackHandleAPI({
        handlingOpinion: this.feedBackTextarea,
        id: this.feedBackID,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.showPopup = false;
          this.queryFun();
        }
      });
    },
    // 打开弹窗
    openPopupFun(num, item) {
      // this.feedBackTextarea = item.handlingOpinion;
      // this.feedBackID = item.id;
      // if (num == 1) {
      //   this.disabled = true;
      //   this.currentTime = item.handleTime;
      //   if (item.status == 0) {
      //     this.handlerName = "";
      //   } else {
      //     this.handlerName = item.handlerName;
      //   }
      // } else {
      //   this.handlerName = sessionStorage.getItem("username");
      //   this.disabled = false;
      //   this.currentTime = this.GMTToStrSenior(new Date());
      // }
      // this.showPopup = true;
      // this.complaintContent = item.complaintContent;
      // this.passengerPhone = item.passengerPhone;
      // this.passengerContactInformation = item.passengerContactInformation;
      // this.imageArray = item.annexes;
      //保存搜索条件
      let time1 = "";
      let time2 = "";
      if (this.timeBucketValue) {
        time1 = this.GMTToStr(this.timeBucketValue[0]) + " 00:00:00";
        time2 = this.GMTToStr(this.timeBucketValue[1]) + " 23:59:59";
      }
      let search = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        userType: this.userType,
        handlerName: this.handlerValue,
        status: this.state,
        channelNos: this.channelNos,
        createTimeBegin: time1,
        createTimeEnd: time2,
      };
      sessionStorage.setItem("complaintFeedbackSearch", JSON.stringify(search));
      // 打开反馈详情页
      this.$router.push({
        path: "/projectTravel/feedbackDetail",
        query: {
          id: item.id,
          num,
          status: item.status,
          channelNo: item.channelNo,
        },
      });
    },
    // 关闭弹窗
    closeFun() {
      this.showPopup = false;
    },
    // 数据获取方法
    renderData(time1, time2) {
      sessionStorage.removeItem("complaintFeedbackFlag");
      feedbackAPI({
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        userType: this.userType,
        handlerName: this.handlerValue,
        status: this.state,
        channelNos: this.channelNos,
        createTimeBegin: time1,
        createTimeEnd: time2,
        feedbackTypes: this.feedbackTypes,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data?.total || 0;
        }
      });
    },
    // 点击查询按钮
    queryFun(num) {
      if (num && num == 1) {
        this.currentPage = 1;
      }
      let time1 = "";
      let time2 = "";
      if (this.timeBucketValue) {
        time1 = this.GMTToStr(this.timeBucketValue[0]) + " 00:00:00";
        time2 = this.GMTToStr(this.timeBucketValue[1]) + " 23:59:59";
      }
      this.renderData(time1, time2);
    },
    // 计算表格高度
    computeHeight() {
      const boxHeight = this.$refs["complaintFeedback"].clientHeight;
      const topHeight = this.$refs["ActionBar"].clientHeight;
      this.TableHeight = boxHeight - topHeight - 90;
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.currentPage = page;
      this.queryFun();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.queryFun();
    },
  },
};
</script>

<style lang="scss" scoped>
.img_popup {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
  }
}
/deep/.el-range-separator {
  line-height: 26px !important;
}
.feedbackTable {
  /deep/.complaintContent.newInfo {
    position: relative;
    &::after {
      content: "";
      display: block;
      background: #f00;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: 0px;
      overflow: initial;
    }
  }
}

.feedbackPopup {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  .box {
    width: 400px;
    border-radius: 12px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .content {
      padding: 20px;
      .row {
        width: 100%;
        display: flex;
        margin: 24px 0;
      }
      .row-l {
        width: 80px;
        font-size: 14px;
        text-align: right;
        padding-right: 20px;
      }
      .row-r {
        width: calc(100% - 100px);
        font-size: 14px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 6px;
          margin-right: 10px;
        }
        /deep/.el-textarea__inner {
          height: 110px;
          resize: none;
        }
      }
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 24px 18px 24px;
      height: 32px;
      width: calc(100% - 48px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
.complaintFeedback {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 40px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 70px !important;
      }
      .col-1 {
        width: 85px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 70px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 85px);
        .el-select,
        .el-cascader {
          width: 100%;
        }
        .el-cascader {
          ::v-deep .el-input__inner {
            height: 32px !important;
          }
        }
      }
    }
  }
}
</style>
